<template>
    <div class="set-up-groups">
        <el-form
            :model="ruleForm"
            :rules="rules"
            label-position="right"
            ref="ruleForm"
            class="demo-ruleForm"
        >
            <el-form-item
                label="选择设备"
                prop="equipment"
            >
                <div>
                    <el-checkbox
                        v-model="checkedAll"
                        @change="handlerClickChangeChecked"
                        >全选</el-checkbox
                    >
                </div>
                <div class="list-wrapper">
                    <div
                        v-for="(item, index) in deviceList"
                        :key="index"
                        class="equipment-item"
                    >
                        <div>
                            <div class="equipment-item-all-checked">
                                <el-checkbox
                                    v-model="item.checked"
                                    :indeterminate="item.isIndeterminate"
                                    :disabled="item.child.length == 0"
                                    @change="handlerClickChangePitemChecked(item)"
                                    >{{ item.name }}</el-checkbox
                                >
                            </div>
                            <div class="equipment-item-child">
                                <span
                                    v-for="(subItem, subIndex) in item.child"
                                    :key="subIndex"
                                    class="subItem-wrapper"
                                >
                                    <el-checkbox
                                        v-model="subItem.checked"
                                        @change="handlerItemChange(item, subItem)"
                                    >
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="subItem.deviceName"
                                            placement="top-start"
                                        >
                                            <div class="sub-name">
                                                {{ subItem.deviceName }}
                                            </div>
                                        </el-tooltip>
                                    </el-checkbox>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form-item>

            <el-form-item
                v-if="dialogObj.val == '1'"
                label="分组"
                prop="region"
            >
                <el-select
                    v-model="ruleForm.region"
                    placeholder="请选择分组(单选)"
                    style="width: 400px"
                >
                    <el-option
                        v-for="(item, index) of grouping"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <div class="des-wrapper">
                    无分组可选？请点击<span
                        class="des-text"
                        @click="handlerClick"
                        >监控分组</span
                    >进行添加
                </div>
            </el-form-item>
            <el-form-item
                v-if="dialogObj.val == '2'"
                label="部门权限"
                prop="departmentalAuthority"
            >
                <el-input
                    v-model="ruleForm.departmentalAuthority"
                    readonly
                    style="width: 400px"
                    placeholder="请选择部门人员(多选)"
                    @focus="handlerDepartmentalAuthorityDialog"
                ></el-input>
                <div class="des-wrapper des-left">
                    所选人员有权查看当前设备监控
                </div>
            </el-form-item>
        </el-form>
        <div class="btn-box">
            <el-button style="width:64px;height:36px" @click="handlerCloseDialog">取消</el-button>
            <el-button
                style="width:64px;height:36px"
                type="primary"
                @click="handlerDetermine"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "SetUpGroups",
    props: {
        dialogObj: Object,
        deviceList: Array,
        grouping: Array,
        selPopUpDepartmentObjData: Array
    },
    created () {
        let _this = this;
        this.$on('initData', () => {
            _this.initData();
        })
    },
    data() {
        return {
            ruleForm: {
                equipment: [],
                region: '',
                departmentalAuthority: '',
                limitsId: ''
            },
            rules: {
                equipment: [
                    {
                        required: true,
                        message: "请选择设备",
                        trigger: ["blur", "change"],
                    },
                ],
            },
            checkedAll: false,
            list: [],
        };
    },
    methods: {
        initData () {
            this.ruleForm.equipment = [];
            this.ruleForm.region = '';
            this.ruleForm.departmentalAuthority = '';
            this.checkedAll = false;
        },
        handlerClickChangeChecked(val) {
            let selectAll = [];
            if (val) {
                console.log(this.deviceList, val, "this.list");
                this.deviceList.forEach((item) => {
                    this.$set(item, "isIndeterminate", false);
                    this.$set(item, "checked", true);

                    item.child.forEach((subItem) => {
                        this.$set(subItem, "checked", true);
                        selectAll.push(subItem.id);
                    });
                });
                console.log(selectAll, "selectAll");
                this.ruleForm.equipment = selectAll

            } else {
                this.deviceList.forEach((item) => {
                    this.$set(item, "isIndeterminate", false);
                    this.$set(item, "checked", false);

                    item.child.forEach((subItem) => {
                        this.$set(subItem, "checked", false);
                    });
                });
                this.ruleForm.equipment = [];
            }
        },
        /**
         * @Description: 组选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 18:12:43
         */
         handlerClickChangePitemChecked (pItem) {
            this.deviceList.forEach((item) => {
                if (pItem.id == item.id) {
                    this.$set(pItem, 'checked', pItem.checked)
                    this.$set(pItem, 'isIndeterminate', false)
                    pItem.child.forEach((sItem) => {
                        this.$set(sItem, 'checked', pItem.checked)
                    })
                }
            })
            this.getAllItemChecked()

            if (!pItem.checked) {
                this.checkedAll = false
            }
         },
        /**
         * @Description: 获取所有已知选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 21:48:34
         */
         getAllItemChecked () {
            let res = [];
            this.deviceList.forEach((item) => {
                if (item.child.length > 0) {
                    item.child.forEach((subItem) => {
                        if (subItem.checked) {
                            res.push(subItem.id);
                        }
                    })
                }
            })
            this.ruleForm.equipment = res;
         },
        /**
         * @Description: 单个选择
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 17:38:01
         */
        handlerItemChange(pItem, cItem) {
            console.log(pItem,cItem,'pItem, subItem')
            let res = [];
            let type = true,
                subType = false;
            // 是否全选或者半选
            this.deviceList.forEach((item) => {
                if (item.id == pItem.id) {
                    if (item.child.length > 0) {
                        item.child.forEach((cItem) => {
                            if (!cItem.checked) {
                                type = false;
                            }
                            if (cItem.checked) {
                                subType = true;
                            }
                        })
                    }
                }
            })
            console.log(subType,type,'subType')
            this.deviceList.forEach((item) => {
                if (item.id == pItem.id) {

                    if (subType){
                        this.$set(item, 'isIndeterminate', true)
                    } else {
                        this.$set(item, 'isIndeterminate', false)
                    }

                    if (type) {
                        this.$set(item, 'isIndeterminate', false)
                        this.$set(item, 'checked', true)
                    } else {
                        this.$set(item, 'checked', false)
                    }
                }
            })


            // 获取所有值
            this.deviceList.forEach((item) => {
                if (item.child.length > 0) {
                    item.child.forEach((subItem) => {
                        if (subItem.checked) {
                            res.push(subItem.id);
                        }
                    });
                }
            });
            this.ruleForm.equipment = res;

            // 判断全选
            let allChecked = true;
            this.deviceList.forEach((item) => {
                // if (!item.checked) {
                //     console.log(item,'item0item')
                //     allChecked = false;
                // }

                if (item.child.length > 0) {
                    item.child.forEach((subItem) => {
                        if (!subItem.checked) {
                            allChecked = false;
                        }
                    })
                }
            })
            console.log(allChecked,'allChecked')
            if (!allChecked) {
                this.checkedAll = false;
            } else {
                this.checkedAll = true;
            };
        },
        /**
         * @Description: 部门权限
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 14:12:10
         */
        handlerDepartmentalAuthorityDialog() {
            this.$emit("handlerDepartmentalAuthorityDialog");
        },
        /**
         * @Description: 点击监控分组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 10:52:00
         */
        handlerClick() {
            this.handlerCloseDialog();
            this.$router.push({name: 'SystemDict'})
        },
        /**
         * @Description: 关闭
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 14:06:31
         */
        handlerCloseDialog() {
            this.$emit("handlerCloseDialog");
        },
        /**
         * @Description: 确定
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 14:06:50
         */
        handlerDetermine() {
            // console.log(this.ruleForm,'ruleForm')
            this.$emit("handlerDetermine", {ruleForm: this.ruleForm});
        },
    },
    watch: {
        deviceList: {
            handler() {
                this.list = this.deviceList;
                console.log(this.list, "this.list ");
            },
            deep: true,
        },
        'selPopUpDepartmentObjData': {
            handler () {
                this.ruleForm.departmentalAuthority = this.selPopUpDepartmentObjData.map(item => item.name).join(',');
                this.ruleForm.limitsId = JSON.stringify(this.selPopUpDepartmentObjData)
                console.log(this.selPopUpDepartmentObjData,'selPopUpDepartmentObjData')
            },
            deep: true
        },
        'ruleForm': {
            handler () {
                console.log(this.ruleForm,'ruleForm')
            },
            deep: true
        }
    },
};
</script>
<style lang="scss" scoped>
.set-up-groups {
    box-sizing: border-box;
    padding: 0px 0px 7px 36px;
}
.list-wrapper {
    margin-top: 12px;
    max-height: 500px;
    overflow: auto;
}
.equipment-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .equipment-item-all-checked {
        width: 688px;
        height: 28px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 10px;
        background: linear-gradient(
            90deg,
            #f5f6f7 0%,
            rgba(245, 246, 247, 0.1) 100%
        );
        border-radius: 4px;
    }
    .equipment-item-child {
        box-sizing: border-box;
        padding: 15px 10px;
        .subItem-wrapper {
            margin: 0 30px 18px 0;
            /deep/ .el-checkbox__input {
                margin-top: -10px !important;
            }
            .sub-name {
                width: 83px;
                overflow: hidden;
                vertical-align: bottom;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
.des-wrapper {
    margin-left: 40px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808487;
    .des-text {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
        cursor: pointer;
    }
}

.des-left {
    margin-left: 68px;
}
.btn-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
</style>
