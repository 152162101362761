<template>
    <div class="editing-equipment common-form">
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="94px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="设备名称"
                prop="name"
            >
                {{ ruleForm.name }}
            </el-form-item>
            <el-form-item
                label="设备别名"
                prop="deviceAlias"
            >
                <el-input
                    v-model="ruleForm.deviceAlias"
                    clearable
                    placeholder="请输入设备别名"
                    maxlength="20"
                    show-word-limit
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="排序"
                prop="sort"
            >
                <el-input
                    v-model="ruleForm.sort"
                    clearable
                    placeholder="请输入排序"
                    class="input-width-medium"
                ></el-input>
            </el-form-item>
            <el-form-item
                label="分组"
                prop="groupCode"
            >
                <el-select
                    v-model="ruleForm.groupCode"
                    placeholder="请选择分组（单选）"
                    class="input-width-medium"
                >
                    <el-option
                        v-for="(item, index) in grouping"
                        :label="item.label"
                        :value="item.value"
                        :key="index"
                    ></el-option>
                </el-select>
                <span class="des">无分组可选？请点击<span @click="handlerClick" class="monitoring-group">监控分组</span>进行添加</span>
            </el-form-item>
            <el-form-item label="所属班级">
                <el-cascader
                    v-model="ruleForm.classId"
                    :options="classList"
                    :props="cascaderProps"
                    collapse-tags
                    class="input-width-medium"
                    placeholder="请选择班级（多选）"
                    ></el-cascader>
                    <span class="des">有对应班级权限的人员可查看设备监控</span>
            </el-form-item>
            <el-form-item
                label="部门权限"
                prop="delivery"
            >
                <el-input
                    v-model="ruleForm.limitsName"
                    readonly
                    class="input-width-medium"
                    placeholder="请选择部门人员（多选）"
                    @focus="handlerEditDepartmentDialog"
                ></el-input>
                <span class="des">所选人员有权限查看当前设备监控</span>
            </el-form-item>
            <div class="btn-box">
                <el-button @click="handlerEditCloseDialog">取消</el-button>
                <el-button
                    type="primary"
                    @click="handlerEditDetermine"
                    >确认</el-button
                >
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "EditingEquipment",
    props: {
        editRuleForm: Object,
        grouping: Array,
        selPopUpDepartmentObjData: Array,
        classList: Array
    },
    data() {
        return {
            ruleForm: {
                id: "",
                name: "",
                groupCode: "",
                groupName: "",
                classId: [],
                limitsId: "",
                limitsName: '',
                deviceAlias: "",
                sort: "",
            },
            classIds: [],
            cascaderProps: {
                multiple: true,
                label: "name",
                value: "id",
                children: 'children'
            },
            rules: {
                deviceAlias: [
                    {
                        required: true,
                        message: "请输入设备别名",
                        trigger: "blur",
                    },
                ],
                sort: [
                    {
                        required: true,
                        message: "请输入排序",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[1-9]\d{0,2}$/,
                        message: "只能输入正整数，最多输入3位整数",
                    }
                ],
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            Object.keys(this.ruleForm).forEach((key) => {
                Object.keys(this.editRuleForm).forEach((subKey) => {
                    if (key == subKey && key !== 'limitsId') {
                        this.ruleForm[key] = this.editRuleForm[subKey];
                    }
                });
            });
        },
        handlerEditDepartmentDialog() {
            this.$emit("handlerEditDepartmentDialog");
        },
        changeSel (val) {
            console.log(val,'val')
        },
        handlerEditCloseDialog() {
            this.$emit('handlerEditCloseDialog')
        },
        handlerEditDetermine() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    this.$emit('handlerEditDetermine', this.ruleForm)
                }
            })
        },
         handlerClick() {
            this.handlerEditCloseDialog();
            this.$router.push({name: 'SystemDict'})
        },
    },
    watch: {
        editRuleForm: {
            handler() {
                console.log(this.editRuleForm, "editRuleForm变化");
            },
            deep: true,
        },
        ruleForm: {
            handler() {
                console.log(this.ruleForm, "ruleForm变化");
            },
            deep: true,
        },
        selPopUpDepartmentObjData: {
            handler() {
                if (this.selPopUpDepartmentObjData.length > 0) {
                    this.ruleForm.limitsName = this.selPopUpDepartmentObjData
                    .map((item) => item.name)
                    .join(",");

                    this.ruleForm.limitsId = JSON.stringify(this.selPopUpDepartmentObjData)
                } else {
                    this.ruleForm.limitsName = '';
                    this.ruleForm.limitsId = '';
                }

                console.log(
                    this.selPopUpDepartmentObjData,
                    "selPopUpDepartmentObjData",
                );
            },
            deep: true,
            immediate: true
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.des {
    display: block;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808487;
    line-height: 24px;
    .monitoring-group {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
        cursor: pointer;
    }
}
</style>
